import React from 'react';
import { GoogleLogout } from 'react-google-login';

import { Box, Grid, Typography, makeStyles } from '@material-ui/core';

import { theme } from 'src/themes';
import logo from 'src/images/logo.svg';
import User from 'src/models/User';

const useStyles = makeStyles((_) => ({
  wrapper: {
    height: '70px',
    paddingTop: '6px',
    paddingLeft: '6px',
    backgroundColor: theme.colors.darkBlue,
  },
  header: {
    color: theme.colors.white,
  },
  websiteNameWrapper: {
    textAlign: 'center',
  },
  liveHeader: {
    color: 'red',
  },
}));

interface Props {
  setUser: (u: User | null) => void;
  googleClientId: string;
  isLive: boolean;
}

export default function Header(props: Props) {
  const classes = useStyles();

  function onLogout() {
    props.setUser(null);
  }

  return (
    <Box className={classes.wrapper}>
      <Grid container direction="row" alignItems="center" justify="space-between">
        <Grid item xs={4}>
          <img src={logo} alt="Via logo" />
        </Grid>
        <Grid item xs={4} className={classes.websiteNameWrapper}>
          <Typography variant="h5" component="h1" className={classes.header}>
            Routes Manager Data Upload {props.isLive && <span className={classes.liveHeader}>Live Environment</span>}
          </Typography>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
        <GoogleLogout
            clientId={props.googleClientId}
            buttonText="Logout"
            onLogoutSuccess={onLogout}
          />
        </Grid>
      </Grid>
    </Box>
  );
}