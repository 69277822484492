import { DataVersion } from "./Version";

export interface DataUpload {
  id: string;
  timestamp?: Date;
  cityId: number;
  uploadedByUser: string;
  status: DataUploadStatus;
  error: DataUploadError | null;
}

export enum DataUploadErrorStep {
  DataParsing, GTFSProcessing,
};

export enum DataUploadErrorCode {
  Internal,  InvalidDate,  InvalidYesNoText,  InvalidProperty,  WrongCsvColumns,  InvalidNumber,  MissingStopInData,
};

export interface DataUploadError {
  step: DataUploadErrorStep;
  code: DataUploadErrorCode;
  invalidProperty: string | null;
  invalidValue: string | null;
  stopId: string | null;
  rowNumber: number | null;
  fileType: string | null;
};

export function textFromDataUploadError(e: DataUploadError): string {
  let res = '';
  switch(e.code) {
    case DataUploadErrorCode.Internal:
      res = `An error occurred while uploading files. Please reach out to the tech support for assistance.`;
      break;
    case DataUploadErrorCode.InvalidDate:
      res = `${e.invalidProperty}${e.fileType? ` in file ${e.fileType}`: ''} needs to be a date. Current value ${e.rowNumber? `in row ${e.rowNumber} `: ''}is: ${e.invalidValue}`;
      break;
    case DataUploadErrorCode.InvalidYesNoText:
      res = `${e.invalidProperty}${e.fileType? ` in file ${e.fileType}`: ''} needs to be one of the following: Yes, No. Current value ${e.rowNumber? `in row ${e.rowNumber} `: ''}is: ${e.invalidValue}`;
      break;
    case DataUploadErrorCode.InvalidProperty:
      res = `${e.invalidProperty}${e.fileType? ` in file ${e.fileType}`: ''} contains invalid value ${e.rowNumber? `in row ${e.rowNumber} `: ''}: ${e.invalidValue}`;
      break;
    case DataUploadErrorCode.WrongCsvColumns:
      res = `${e.fileType} file has invalid number of columns. `;
      break;
    case DataUploadErrorCode.InvalidNumber:
      res = `${e.invalidProperty}${e.fileType? ` in file ${e.fileType}`: ''} needs to be a number. Current value ${e.rowNumber? `in row ${e.rowNumber} `: ''}is: ${e.invalidValue}`;
      break;
    case DataUploadErrorCode.MissingStopInData:
      res = `Stop id ${e.stopId} is included in trip, but missing in stops file.`;
      break;
    default:
      res += 'Unknown';
  }
  return res;
}

export enum DataUploadStatus {
  CREATED, READY, FAILED
};

export interface DataUploadDetails {
  version: DataVersion | null;
  lines: Line[];
  timestamp?: Date;
}

export interface DataUploadInformation {
  details?: DataUploadDetails;
  error?: DataUploadError;
}

export enum ErrorSeverity {
  Error, Warning, Info, Unknown
}

export interface LineError {
  message: string;
  severity: ErrorSeverity;
}

export interface Line {
  errors: LineError[];
  shortName: string;
  headsign: string;
}