import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Link } from '@material-ui/core';
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';

import User from 'src/models/User';

import bgImage from 'src/images/bg-login.svg';
import logo from 'src/images/logo.svg';
import {theme} from 'src/themes';

const useStyles = makeStyles((_) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${bgImage})`,
  },
  paper: {
    height: '300px',
    overflow: 'auto',
    margin: 'auto',
    padding: 0,
    position: 'absolute',
    top: 0, left: 0, bottom: 0, right: 0,
    backgroundColor: theme.colors.white,
  },
  header: {
    textAlign: 'center',
    height: '100px',
    backgroundColor: theme.colors.lightGray,
  },
  logo: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  buttonWrapper: {
    textAlign: 'center',
    paddingTop: '50px',
  },
  permissionsText: {
    display: 'block',
    margin: '20px',
    color: theme.colors.lightBlue,
  }
}));

interface Props {
  googleClientId: string;
  setUser(user: User | null): void;
};

export default function SignIn(props: Props) {
  const classes = useStyles();

  function onFailure(err: any) {
    console.error(err);
  }

  function onSuccess(response: GoogleLoginResponse | GoogleLoginResponseOffline) {
    response = response as GoogleLoginResponse;
    props.setUser({email: response.profileObj.email, token: response.tokenId});
  }

  return (
    <div className={classes.wrapper}>
      <Container className={classes.paper} maxWidth="xs">
        <div className={classes.header}>
          <img className={classes.logo} src={logo} alt="Via logo" />
        </div>
        <div className={classes.buttonWrapper}>
          <GoogleLogin 
            clientId={props.googleClientId}
            buttonText="Sign in with Google"
            onSuccess={onSuccess}
            onFailure={onFailure}
            isSignedIn={true}
            cookiePolicy={"single_host_origin"}
          />
          <Link href="mailto:georgi.yovchev@ridewithvia.com" className={classes.permissionsText}>
            Logging in for the first time and don't have permissions?
          </Link>
        </div>
      </Container>
    </div>
  );
}