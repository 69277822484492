export interface CityFile {
  name: string;
  humanReadableName: string;
}

export interface City {
  cityId: number;
  cityCode: string;
  tenantId: string;
  files: CityFile[];
}

export class Cities {
  public list: City[] = [];
  private map: Record<number, City> = {};

  constructor (list: City[]) {
    this.list = list;
    this.map = list.reduce((map, city) => ({...map, [city.cityId]: city}), {});
  }

  public fromCity(id: number): City | null {
    return this.map[id];
  }
}