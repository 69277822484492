import React, { useState, useEffect, useCallback } from 'react';

import { theme } from 'src/themes';

import { City } from 'src/models/City';

import { Paper, Table, TableHead, TableRow, TablePagination, TableCell, TableBody, withStyles, createStyles, TableContainer, Toolbar, Typography, Tooltip, IconButton, Grid } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';

import { DataUpload, DataUploadInformation, DataUploadStatus } from 'src/models/DataUpload';
import Api from 'src/api';
import DataUploadModal from './DataUploadModal/DataUploadModal';

interface Props {
  api: Api;
  city: City | null;
  setError: (msg: string) => void;
};

const HeaderTableRow = withStyles((_) =>
  createStyles({
    root: {
      backgroundColor: theme.colors.lightBlue,
    },
  }),
)(TableRow);

const HeaderTableCell = withStyles((_) =>
  createStyles({
    root: {
      color: theme.colors.white,
      fontWeight: 500,
      fontSize: '1.2em',
    },
  }),
)(TableCell);

const DataTableRow = withStyles((_) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.colors.lightGray,
      },
      cursor: 'pointer',
    },
  }),
)(TableRow);

const DataTableCell = withStyles((_) =>
  createStyles({
    root: {
      color: theme.colors.darkBlue,
      fontWeight: 500,
      fontSize: '1em',
    },
  }),
)(TableCell);

export default function DataTable(props: Props) {

  const [dataUploads, setDataUploads] = useState<DataUpload[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [uploadInfo, setUploadInfo] = React.useState<DataUploadInformation | null>(null);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const { city, api, setError } = props;
  const updateUploads = useCallback(() => {
    if (city === null) {
      return;
    }
    api.getCityUploads(city.cityId, city.tenantId).then(uploads => {
      setDataUploads(uploads);
    }).catch((err: Error) => {
      console.error(err);
      setError('Error occurred while fetching data uploads.');
    });
  }, [city, api, setError]);

  useEffect(() => {
    updateUploads();
  }, [updateUploads]);

  const showUploadDetails = (upload: DataUpload) => {
    if (props.city === null || props.city.cityId === null) 
      return;
    if (upload.error) {
      setUploadInfo({error: upload.error});
      return;
    }
    props.api.getDataUploadDetails(props.city.cityId, upload.id).then(res => {
      res.timestamp = upload.timestamp;
      setUploadInfo({details: res});
    }).catch(e => {
      console.error(e);
      props.setError('couldn\'t get upload details');
    });
  };

  function dataUploadToTableRow(row: DataUpload) {
    let status = '';
    let statusColor = 'black';
    if (row.status === DataUploadStatus.READY) {
      status = 'Succeeded';
      statusColor = 'green';
    }
    else if (row.status === DataUploadStatus.CREATED) {
      status = 'In progress';
      statusColor = 'blue';
    }
    else if (row.status === DataUploadStatus.FAILED) {
      status = 'Failed';
      statusColor = 'red';
    }
    else status = 'None';
    return (
      <DataTableRow key={row.id} onClick={() => showUploadDetails(row)}>
        <DataTableCell align="center">
          {row.timestamp?.toLocaleString('en-GB')}
        </DataTableCell>
        <DataTableCell align="center">{row.uploadedByUser}</DataTableCell>
        <DataTableCell align="center" style={{color: statusColor, fontWeight: 'bold'}}>{status}</DataTableCell>
      </DataTableRow>
    );
  }

  return (
    <>
      <DataUploadModal info={uploadInfo} onClose={() => setUploadInfo(null)}/>
      <TableContainer component={Paper}>
        <Toolbar>
          <Grid container direction="row" alignItems="center" justify="space-between">
            <Grid item xs={4}>
              <Typography variant="h6" id="tableTitle" component="div">
                Data versions
              </Typography>
            </Grid>
            <Grid item xs={7}></Grid>
            <Grid item xs={1}>
              <Tooltip title="Refresh">
                <IconButton aria-label="Refresh">
                  <RefreshIcon onClick={updateUploads}/>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
        <Table>
          <TableHead>
            <HeaderTableRow>
              <HeaderTableCell align="center">Upload time</HeaderTableCell>
              <HeaderTableCell align="center">Uploaded by</HeaderTableCell>
              <HeaderTableCell align="center">Status</HeaderTableCell>
            </HeaderTableRow>
          </TableHead>
          <TableBody>
            {dataUploads
              .sort((u1, u2) => (u1.timestamp?.getTime() || 0) > (u2.timestamp?.getTime() || 0) ? -1 : 1)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(dataUploadToTableRow)}
          </TableBody>
        </Table>
        <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={dataUploads.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
      </TableContainer>
    </>
  );
}