import React from 'react';

import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TableContainer, TableRow, TableCell, Table, TableBody, TablePagination, withStyles, createStyles, TableHead } from '@material-ui/core';

import { ErrorSeverity, DataUploadInformation, textFromDataUploadError } from 'src/models/DataUpload'

import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';

import { theme } from 'src/themes';

interface Props {
  info: DataUploadInformation | null;
  onClose: () => void;
};

const DataTableRow = withStyles((_) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.colors.lightGray,
      },
    },
  }),
)(TableRow);

const HeaderTableCell = withStyles((_) =>
  createStyles({
    root: {
      color: theme.colors.darkBlue,
      fontWeight: 'bold',
      fontSize: '1em',
    },
  }),
)(TableCell);

const DataTableCell = withStyles((_) =>
  createStyles({
    root: {
      color: theme.colors.darkBlue,
      fontWeight: 500,
      fontSize: '1em',
    },
  }),
)(TableCell);

export default function DataUploadModal(props: Props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let errorRows: Array<JSX.Element> = [];
  let errorsLength = 0;

  if (props.info?.details !== undefined) {
    const errors = props.info.details.lines
    .flatMap(l => { 
      return l.errors.map(e => {
        return {
          error: e,
          lineName: l.shortName,
          headsign: l.headsign,
        };
      });
    })
    errorsLength = errors.length;
    errorRows = errors.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map(errorRow => (
        <DataTableRow>
          <DataTableCell align='left' scope="row">
          {errorRow.error.severity === ErrorSeverity.Error? <ErrorIcon htmlColor="#ff0033" />: <WarningIcon htmlColor="#ffae42" />}
          </DataTableCell>
          <DataTableCell align='left' scope="row">
            {errorRow.lineName} ({errorRow.headsign})
          </DataTableCell>
          <DataTableCell align='left' scope="row">
            {errorRow.error.message}
          </DataTableCell>
        </DataTableRow>
      ));
  } else if(props.info?.error !== undefined) {
    errorRows = [(
      <DataTableRow>
          <DataTableCell align='left' size='small' scope="row">
            <ErrorIcon htmlColor="#ff0033" />
          </DataTableCell>
          <DataTableCell align='left' scope="row">
            {textFromDataUploadError(props.info.error)}
          </DataTableCell>
        </DataTableRow>
    )]
  }

  return (
    <Dialog
      open={props.info !== null}
      onClose={props.onClose}
      maxWidth='md'
      fullWidth={true}
    >
      {props.info?.details && (
        <DialogTitle>{ props.info.details?.timestamp?.toLocaleString('en-GB') }</DialogTitle>
      )}
      <DialogContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <HeaderTableCell size='small' align="left">Severity</HeaderTableCell>
                {props.info?.details && (
                  <HeaderTableCell align="left">Route name</HeaderTableCell>
                )}
                <HeaderTableCell align="left">Details</HeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {errorRows}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30, 50]}
            component="div"
            count={errorsLength}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary" autoFocus>
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
}