import React from "react";
import { useDropzone } from "react-dropzone";
import "./Dropzone.css";

/**
    Copy-pasted from VOC with small changes in the stylesheet
 */

interface DropzoneProps {
  value: File | null;
  text: string;
  handlerOnUploadFile: any;
  acceptFormat: string;
  icon?: string;
};

const Dropzone = (props: DropzoneProps) => {
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop: props.handlerOnUploadFile });
    const files = acceptedFiles.map((file) => (
        <span className="showFile" key={file.name}>
            {file.name}
        </span>
    ));

    return (
        <section className="dropzoneContainer">
            <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps({ accept: props.acceptFormat, onChange: props.handlerOnUploadFile })} />
                <div className="dropContent">
                    {files.length && props.value ? (
                        <aside>{files}</aside>
                    ) : (
                        <>
                            <span className="uploadHeader">{props.text}</span>
                            <span className="smallText">
                            Browse
                            <div className="grey">or</div>
                            Drag &amp; drop
                            </span>
                        </>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Dropzone;
