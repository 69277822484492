import React, { useState, useEffect } from 'react';
import { Error } from 'grpc-web';

import Header from './Header/Header';
import DataTable from './DataTable/DataTable';
import UploadFiles from './UploadFiles/UploadFiles';
import ErrorMessage from './ErrorMessage/ErrorMessage';

import { Paper, Container, makeStyles, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import { Cities, City } from 'src/models/City';
import { theme } from 'src/themes';
import Api from 'src/api';
import User from 'src/models/User';

const useStyles = makeStyles((_) => ({
  baseContainer: {
    marginTop: '60px',
  },
  paper: {
    paddingTop: '10px',
    paddingBottom: '10px',
    marginTop: '20px',
    marginBottom: '20px',
  },
  formControl: {
    margin: '1px',
    minWidth: 120,
  },
  selectDiv: {
    textAlign: 'center'
  },
  button: {
    backgroundColor: theme.colors.lightBlue,
    width: '200px',
    color: theme.colors.white,
    "&:hover": {
      backgroundColor: theme.colors.white,
      color: theme.colors.lightBlue,
    }
  },
}));

interface Props {
  api: Api;
  setUser: (u: User | null) => void;
  googleClientId: string;
  isLive: boolean;
};

export default function DataPage(props: Props) {
  const classes = useStyles();
  const [allCities, setAllCities] = useState(new Cities([]));
  const [city, setCity] = useState<City | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let isMounted = true;
    props.api.getCityTenantPairs().then(cities => {
      if(isMounted)  {
        if (cities.list.length < 1) {
          setError('Looks like you don’t have permissions. Please contact the tech support for permissions');
        }
        setAllCities(cities);
      }
    }).catch((err: Error) => {
      console.error(err);
      if(isMounted) setError('Error occurred while fetching city/tenant pairs.');
    });
    return () => { isMounted = false };
  }, [props.api]);

  
  return (
    <>
      <Header setUser={props.setUser} googleClientId={props.googleClientId} isLive={props.isLive}/>
      <Container className={classes.baseContainer}>
        <Paper className={classes.paper}>
          <div className={classes.selectDiv}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="city-id-label">City id</InputLabel>
              <Select
                labelId="city-id-label"
                value={city?.cityId}
                onChange={(event: any) => setCity(allCities.fromCity(event.target.value))}
                label="City id"
              >
                {allCities.list.map(city =>
                  <MenuItem value={city.cityId} key={city.cityId}>{`${city.cityId} (${city.cityCode})`}</MenuItem>
                )}
              </Select>
            </FormControl>
          </div>
        </Paper>
        {city !== null && (
          <>
            <Paper className={classes.paper}>
              <UploadFiles city={city} api={props.api} onError={setError} />
            </Paper>
            <Paper className={classes.paper}>
              <Container maxWidth="md">
                <DataTable city={city} setError={setError} api={props.api}  />
              </Container>
            </Paper>
          </>
        )}
        {error != null && (
          <ErrorMessage message={error} onClose={() => setError(null)} />
        )}
      </Container>
    </>
  );
}