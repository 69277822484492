import {sha256} from 'js-sha256';

import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import {DataServicePromiseClient} from "grpc-web-via-bg-route-apis/dataapi/v1/api_grpc_web_pb";
import { UploadFileBulkRequest, GetDataUploadDetailsRequest, GetCityTenantPairsResponse, GetDataUploadsRequest } from "grpc-web-via-bg-route-apis/dataapi/v1/api_pb";
import { CityTenantPair, ProcessorType } from "grpc-web-via-bg-route-apis/dataapi/v1/city_pb";
import * as pbUpload from "grpc-web-via-bg-route-apis/dataapi/v1/dataupload_pb";
import * as pbError from "grpc-web-via-bg-route-apis/dataapi/v1/error_pb";
import * as pbVersion from "grpc-web-via-bg-route-apis/dataapi/v1/version_pb";

import { CityFile, Cities, City } from 'src/models/City';
import { ErrorSeverity, DataVersion, DataVersionStatus } from 'src/models/Version';
import { DataUpload, DataUploadStatus, DataUploadDetails, DataUploadError, DataUploadErrorStep, DataUploadErrorCode } from 'src/models/DataUpload';

export default class Api {
  
  private token: string;
  private clients: DataServicePromiseClient[] = [];
  private cityIdToClient: Record<number, DataServicePromiseClient> = {};
  private onReqStart: () => void;
  private onReqEnd: () => void;


  constructor(hosts: string[], token: string, isLive: boolean, onReqStart: () => void, onReqEnd: () => void) {
    this.token = token;
    hosts.forEach(h => {
      this.clients.push(new DataServicePromiseClient(h));
    });
    const enableDevTools = (window as any).__GRPCWEB_DEVTOOLS__ || (() => {});
    if (!isLive) enableDevTools(this.clients);
    this.onReqStart = onReqStart;
    this.onReqEnd = onReqEnd;
  }

  private mapProcessorTypeFiles(procType: ProcessorType): CityFile[] {
    switch (procType) {
      case ProcessorType.GENERICPARSER:
        return [
          {name: 'trips.csv', humanReadableName: 'Trips'}, 
          {name: 'trip_stops.csv', humanReadableName: 'Trip stops'}, 
          {name: 'stops.csv', humanReadableName: 'Stops'}
        ];
      case ProcessorType.DOEPARSER:
        return [{name: 'data.csv', humanReadableName: 'data'}];
      default:
        return [];
    }
  }

  public async getCityTenantPairs(): Promise<Cities> {
    this.onReqStart();
    try {
      const calls = this.clients.map(c => c.getCityTenantPairs(new google_protobuf_empty_pb.Empty(), { 'Authorization': `Bearer ${this.token}` }));
      const promiseResults = await Promise.allSettled(calls);
      let responses: GetCityTenantPairsResponse[] = [];
      for(let pr of promiseResults) {
        if (pr.status === 'fulfilled') {
          responses.push(pr.value);
        } else {
          console.error('error while getting city/tenant pairs: ' + pr.reason.message);
        }
      }
      for(let i = 0; i < responses.length; i++) {
        let list = responses[i].getListList();
        for (let j = 0; j < list.length; j++) {
          this.cityIdToClient[list[j].getCityId()] = this.clients[i];
        }
      }
      let pbPairs = responses.reduce((prevValue, currentValue) => [...prevValue, ...currentValue.getListList()], [] as CityTenantPair[]);
      return new Cities(pbPairs.map((p: CityTenantPair) => {
        return {
          cityId: p.getCityId(),
          tenantId: p.getTenantId(),
          cityCode: p.getCityCode(),
          files: this.mapProcessorTypeFiles(p.getProcessorType())
        }
      }));
    } finally {
      this.onReqEnd();      
    }
  }

  public async getCityUploads(cityId: number, tenantId: string): Promise<DataUpload[]> {
    this.onReqStart();
    try {
      let client = this.cityIdToClient[cityId];
      if (!client) {
        throw new Error('We don\'t have a client for this city id');
      }
      let req = new GetDataUploadsRequest();
      req.setCityId(cityId);
      req.setTenantId(tenantId);
      let response;
      try {
        response = await client.getDataUploads(req, { 'Authorization': `Bearer ${this.token}` });
      } catch(e) {
        if (e.code === 7) { // refresh if Permission Denied
          window.location.reload();
        }
        throw e;
      }
      let pbList = response.getDataUploadsList();
      return pbList.map((u: pbUpload.DataUpload) => {
        if (u.getUploadTimestamp() === null) {
          throw new Error('got a version with empty timestamp');
        }
        let status: DataUploadStatus;
        switch (u.getStatus()) {
          case pbUpload.DataUploadStatus.FAILED:
            status = DataUploadStatus.FAILED;
            break;
          case pbUpload.DataUploadStatus.READY:
            status = DataUploadStatus.READY;
            break;
          case pbUpload.DataUploadStatus.CREATED:
            status = DataUploadStatus.CREATED;
            break;
        }
        let error = this.pbDataUploadErrorToCore(u.getError());
        return {
          id: u.getUuid(),
          timestamp: u.getUploadTimestamp()?.toDate(),
          cityId: u.getCityId(),
          status: status,
          uploadedByUser: u.getUserEmail(),
          error,
        }
      });
    } finally {
      this.onReqEnd();      
    }
  }

  public async getDataUploadDetails(cityId: number, id: string): Promise<DataUploadDetails> {
    this.onReqStart();
    try {
      let client = this.cityIdToClient[cityId];
      if (!client) {
        throw new Error('We don\'t have a client for this city id');
      }
      let req = new GetDataUploadDetailsRequest();
      req.setDataUploadId(id);
      let response;
      try {
        response = await client.getDataUploadDetails(req, { 'Authorization': `Bearer ${this.token}` });
      } catch(e) {
        if (e.code === 7) { // refresh if Permission Denied
          window.location.reload();
        }
        throw e;
      }
      const res = response.getDataUploadDetails();
      const lines = res?.getLinesList() || [];
      return {
        version: this.pbVersionToCore(res?.getVersion()),
        lines: lines.map(l => {
          return {
            shortName: l.getShortName(),
            headsign: l.getHeadsign(),
            errors: l.getErrorsList().map(e => {
              let severity = ErrorSeverity.Unknown;
              switch (e.getSeverity()) {
                case pbError.ErrorSeverity.ERROR:
                  severity = ErrorSeverity.Error
                  break;
                case pbError.ErrorSeverity.INFO:
                  severity = ErrorSeverity.Info
                  break;
                case pbError.ErrorSeverity.WARNING:
                  severity = ErrorSeverity.Warning
                  break;
              }
              return {
                message: e.getMsg(),
                severity,
              };
            })
          };
        })
      };
    } finally {
      this.onReqEnd();      
    }
  }

  private pbVersionToCore(version: pbVersion.Version | undefined): DataVersion | null {
    if (!version) return null;
    if (version.getTimestamp() === null) {
      throw new Error('got a version with empty timestamp');
    }
    let status: DataVersionStatus;
    switch (version.getStatus()) {
      case pbVersion.VersionStatus.DATA_VERSION_CREATED:
        status = DataVersionStatus.CREATED;
        break;
      case pbVersion.VersionStatus.DATA_VERSION_READY:
        status = DataVersionStatus.READY;
        break;
      default:
        throw new Error('invalid status: ' + version.getStatus());
    }
    return {
      id: version.getId(), 
      timestamp: version.getTimestamp()?.toDate(), 
      cityId: version.getCityId(), 
      originDataSize: version.getOriginDataSize(), 
      gtfsDataSize: version.getGtfsDataSize(), 
      isRollbacked: version.getIsRollbacked(), 
      status,
    };
  }

  private pbDataUploadErrorToCore(e: pbUpload.DataUploadError | undefined): DataUploadError | null {
    if (!e) return null;
    
    let step: DataUploadErrorStep;
    switch (e.getStep()) {
      case pbUpload.DataUploadErrorStep.GTFSPARSING:
        step = DataUploadErrorStep.GTFSProcessing;
        break;
      case pbUpload.DataUploadErrorStep.DATAPROCESSING:
        step = DataUploadErrorStep.DataParsing;
        break;
      default:
        throw new Error('invalid data upload error step: ' + e.getStep());
    }
    let code: DataUploadErrorCode;
    switch (e.getCode()) {
      case pbUpload.DataUploadErrorCode.INTERNAL:
        code = DataUploadErrorCode.Internal;
        break;
      case pbUpload.DataUploadErrorCode.INVALIDDATE:
        code = DataUploadErrorCode.InvalidDate;
        break;
      case pbUpload.DataUploadErrorCode.INVALIDYESNOTEXT:
        code = DataUploadErrorCode.InvalidYesNoText;
        break;
      case pbUpload.DataUploadErrorCode.INVALIDPROPERTY:
        code = DataUploadErrorCode.InvalidProperty;
        break;
      case pbUpload.DataUploadErrorCode.WRONGCSVCOLUMNS:
        code = DataUploadErrorCode.WrongCsvColumns;
        break;
      case pbUpload.DataUploadErrorCode.INVALIDNUMBER:
        code = DataUploadErrorCode.InvalidNumber;
        break;
      case pbUpload.DataUploadErrorCode.MISSINGSTOPINDATA:
        code = DataUploadErrorCode.MissingStopInData;
        break;
      default:
        throw new Error('invalid data upload error code: ' + e.getCode());
    }
    return {
      step,
      code,
      invalidProperty: e.getInvalidProperty() || null,
      invalidValue: e.getBrokenValue() || null,
      stopId: e.getStopId() || null,
      rowNumber: e.getRowNumber() || null,
      fileType: e.getFileType() || null,
    };
  }

  public async uploadDataFile(city: City, data: Buffer): Promise<null> {
    this.onReqStart();
    try {
      let client = this.cityIdToClient[city.cityId];
      
      let hash = sha256(data);
      const hex = Buffer.from(hash, 'hex');
      
      let req = new UploadFileBulkRequest();
      let pbCity = new CityTenantPair();
      pbCity.setCityId(city.cityId);
      pbCity.setTenantId(city.tenantId);
      req.setCityId(pbCity);
      req.setData(data);
      req.setShaChecksum(hex);
      try {
        await client.uploadFileBulk(req, { 'Authorization': `Bearer ${this.token}` });
      } catch(e) {
        if (e.code === 7) { // refresh if Permission Denied
          window.location.reload();
        }
        throw e;
      }
    } finally {
      this.onReqEnd();      
    }
    return null;
  }
}