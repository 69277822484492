export enum DataVersionStatus {
  CREATED, READY 
}

export interface DataVersion {
  id: number;
  timestamp?: Date;
  cityId: number;
  originDataSize: number;
  gtfsDataSize: number;
  isRollbacked: boolean;
  status: DataVersionStatus;
}

export interface VersionDetails {
  id: number;
  cityId: number;
  errors: VersionError[];
}

export enum ErrorSeverity {
  Error, Warning, Info, Unknown
}

export interface VersionError {
  severity: ErrorSeverity;
  text: string;
  lineName: string;
}