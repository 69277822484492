import React from 'react';

import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
interface Props {
  message: string;
  onClose: () => void;
};

export default function ErrorMessage(props: Props) {

  return (
    <Dialog
      open={props.message !== null}
      onClose={props.onClose}
    >
      <DialogTitle>{"Error"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}