import React from 'react';

import Dropzone from "../../../../lib/Dropzone/Dropzone";

import iconUpload from "../../../../images/upload-multiple2.png";

interface Props {
  value: File | null;
  onChange(l: File): void;
  onError(msg: string): void;
  humanReadableFileName: string;
}

function FileInput(props: Props) {
  const handlerOnUploadCSV = (files: File[]) => {
    if (files.length > 0) {
      const f = files[0];
      if (f) {
        props.onChange(f);
      }
    }
  };
  return (
    <>
      <div className="uploadContainer">
        <Dropzone
          value={props.value}
          text={`${props.humanReadableFileName} file`}
          icon={iconUpload}
          handlerOnUploadFile={handlerOnUploadCSV}
          acceptFormat=".csv"
        />
      </div>
    </>
  )
}

export default FileInput;