import React, { useState } from 'react';
import { Box, CssBaseline } from '@material-ui/core';

import LoadingScreen from 'src/components/LoadingScreen/LoadingScreen';
import Login from 'src/components/Login/Login';
import DataPage from 'src/components/DataPage/DataPage';
import Copyright from 'src/components/Copyright/Copyright';
import User from 'src/models/User';
import Api from 'src/api';
import Config from 'src/config';

interface AppProps {
  config: Config;
};

export default function App(props: AppProps) {
  const [user, setUser] = useState<User | null>(null);
  const [loadingActions, setLoadingActions] = useState(0);

  const actionLoading = () => {
    setLoadingActions(loadingActions+1);
  }

  const actionLoaded = () => {
    setLoadingActions(Math.max(0, loadingActions-1));
  }

  const [api, setApi] = useState<Api>(new Api([], '', false, actionLoading, actionLoaded));

  function updateUser(user: User) {
    setApi(new Api(props.config.apiUrls, user.token, props.config.isLive, actionLoading, actionLoaded));
    setUser(user);
  }

  return (
    <>
      <LoadingScreen show={loadingActions > 0}/>
      {user === null && <Login setUser={updateUser} googleClientId={props.config.googleClientId} />}
      {user !== null && (
      <>
        <CssBaseline />
        <DataPage api={api} setUser={setUser} googleClientId={props.config.googleClientId} isLive={props.config.isLive}/>
        <Box mt={8}>
          <Copyright />
        </Box>
      </>)}
    </>
  );
}
