import React from 'react';
import ReactDOM from 'react-dom';
import 'src/index.css';

import App from 'src/App';

import reportWebVitals from './reportWebVitals';
import Config from 'src/config';

const config = new Config();

ReactDOM.render(
  <React.StrictMode>
    <App config={config} />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
