export const theme = {
  colors: {
      white: '#FFFFFF',
      black: '#000000',
      lightBlue: '#20B8EA',
      lightGray: '#E4EEF1',
      darkGray: '#8088A0',
      darkBlue: '#063750',
      dirtyWhite: '#FFFFFFB3',
  },
};