export default class Config {
  
  public apiUrls: string[] = [];
  public googleClientId: string = '';
  public isLive: boolean = true;

  constructor() {
    if (process.env.REACT_APP_API_URLS) {
      this.apiUrls = process.env.REACT_APP_API_URLS.split(',');
    }
    if (process.env.REACT_APP_GOOGLE_CLIENT_ID) {
      this.googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    }
    // Defensive if; better false positive for 'isLive' than false negative
    if (process.env.REACT_APP_IS_LIVE?.toLowerCase() === 'false') {
      this.isLive = false;
    }
  }
}