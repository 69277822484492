import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((_) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    marginTop: '0px',
    marginBottom: '0px',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.1)',
    zIndex: 1,
  },
  progress: {
    margin: 0,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  }
}));

interface Props {
  show: boolean;
}

export default function LoadingScreen(props: Props) {
  const classes = useStyles();
  
  return (
    <div className={classes.wrapper} hidden={!props.show}>
      <CircularProgress className={classes.progress}/>
    </div>
  );
}