import React, { useState, useEffect } from 'react';
import archiver from 'archiver';

import { Container, Button, makeStyles, Grid } from '@material-ui/core';

import FileInput from './FileInput/FileInput';

import { City } from 'src/models/City';
import { theme } from 'src/themes';
import Api from 'src/api';

interface Props {
  api: Api;
  onError: (msg: string) => void
  city: City;
};

const useStyles = makeStyles((_) => ({
  buttonWrapper: {
    textAlign: 'center',
  },
  button: {
    backgroundColor: theme.colors.lightBlue,
    width: '250px',
    color: theme.colors.white,
    "&:hover": {
      backgroundColor: theme.colors.white,
      color: theme.colors.lightBlue,
    }
  },
}));


export default function UploadFiles(props: Props) {
  const classes = useStyles();

  function humanReadableFileNamesFromCity(): Record<string, string> {
    if (props.city === null) return {};
    return props.city.files.reduce((acc, file) => ({...acc, [file.name]: file.humanReadableName}), {});
  };

  const humanReadableFileNames = humanReadableFileNamesFromCity();
  
  function filesFromCity(city: City) {
    return city.files.reduce((acc, file) => ({...acc, [file.name]: null}), {});
  };

  const [files, setFiles] = useState<Record<string, File>>(
    filesFromCity(props.city)
  );

  function uploadData(data: Buffer) {
    props.api.uploadDataFile(props.city, Buffer.from(data)).then(() => {
      setFiles(filesFromCity(props.city));
   }).catch((err) => {
     props.onError(err.message);
   })
  }

  function checkForMissingUploads() {
    return Object.keys(files).find(key => files[key] === null) !== undefined;
  }

  async function handleSubmit() {
    const fileNames = Object.keys(files);

    function handleArchivingWait(a: archiver.Archiver) {
      let res = new Buffer('');
      a.on('data',
          (data: Buffer) => res = Buffer.concat([res, data]));
      a.on('error',
          (err: archiver.ArchiverError) => props.onError(err.message));
      a.on('finish',
          () => uploadData(res));
      a.finalize();
    }

    if (fileNames.length > 1) {
      let a = archiver('tar', {
        gzip: true,
      })
      for (let i = 0; i < fileNames.length; i++) {
        const fileName = fileNames[i];
        a = a.append(Buffer.from(await files[fileName].arrayBuffer()), {
          name: fileName,
        });
      }
      handleArchivingWait(a);
    } else if (fileNames.length === 1) {
      let gzipArchiver = archiver('tar', {
        gzip: true,
        gzipOptions : {
          level: 9
        }
      })
      gzipArchiver = gzipArchiver.append(Buffer.from(await files[fileNames[0]].arrayBuffer()), {
        name: fileNames[0],
      })
      handleArchivingWait(gzipArchiver)
    }
  }

  useEffect(() => {
    setFiles(filesFromCity(props.city));
  }, [props.city]);

  
  return (
    <Container maxWidth="md">
      <Grid container justify="center" spacing={4} >
        {Object.keys(files).map(fileName => (
          <Grid key={fileName} item>
            <FileInput 
              humanReadableFileName={humanReadableFileNames[fileName]}
              value={files[fileName]}
              onError={props.onError}
              key={humanReadableFileNames[fileName]} 
              onChange={(f: File) => {
                setFiles({ ...files, [fileName]: f });
              }} />
          </Grid>
        ))}
      </Grid>
      
      <br/>

      <div className={classes.buttonWrapper}>
        <Button
          variant="contained"
          className={classes.button}
          onClick={handleSubmit}
          disabled={checkForMissingUploads()}
        >
          UPLOAD
        </Button>
      </div>
    </Container>
  );
}